/* AUTH */
export const SET_AUTH_LOADING = 'setAuthLoading';
export const SET_AUTH_ERROR = 'setAuthError';
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';

// share
export const SET_USERS = 'setUsers';
export const SET_SHARED_USERS = 'setSharedUsers';

export const SET_VEHICLES = 'setVehicles';
export const SET_VEHICLES_WITH_MODULE = `vehicles/${SET_VEHICLES}`;

// departments
export const SET_DEPARTMENTS = 'setDepartments';
export const SET_GROUPS = 'setGroups';
export const SET_DEPARTMENT = 'setDepartment';
export const SET_SHARED_WORKSPACE_TOKEN = 'setSharedWorkspaceToken';

// status
export const SET_HAS_ANY_TASKS = 'setHasAnyTasks';
export const SET_PLANNED_STATUS = 'setPlannedStatus';
export const SET_HAS_MATERIEL_WITHOUT_ABBREVIATION = 'setHasMaterielWithoutAbbreviation';

// planning view
export const SET_PLANNED_INSTANCE_LOADING = 'setPlannedInstanceLoading';
export const SET_DISTRIBUTION_MODAL_OPEN = 'setDistributionModalOpen';
export const SET_PARTITION_SUMMARY = 'setPartitionSummary';

export const SET_HAS_NON_GEOCODED = 'setHasNonGeocoded';
export const SET_HAS_NON_ZONECODED = 'setHasNonZonecoded';
export const SET_HAS_NON_FREQUENCY_CODED = 'setHasNonFrequencyCoded';

export const SET_MERGE_PLAN = 'setMergePlan';
export const SET_IS_UPLOADING_FOR_IMPORT = 'setIsUploadingForImport';
export const SET_MERGE_USER_CHOSEN_ID = 'setMergeUserChosenId';
export const SET_MERGE_NEW_FREQ_MAPPINGS = 'setMergeNewFreqMappings';
export const SET_MERGE_NEW_FREQS_TO_MAP = 'setMergeNewFreqsToMap';
export const SET_CURRENT_FIELDS_AFTER_IMPORT = 'setCurrentFieldsAfterImport';
export const SET_HAS_EXECUTED_MERGE_PLAN = 'setHasExecutedMergePlan';
export const RESET_FLOW = 'resetMergeFlow';
export const ADD_COMPLETED_MERGE_FLOW_STEP = 'addCompletedMergeFlowStep';
export const SET_FILENAME = 'setFilename';
export const SET_ROUTE_LOADING = 'setRouteLoading';

// jobs
export const SET_JOB_ID_BY_TYPE = 'setJobIdByType';
export const SET_RUNNING_JOBS = 'setRunningJobs';

export const SET_DRIVING_LISTS = 'setDrivingLists';
export const SET_DRIVING_LISTS_LOADING = 'setDrivingListsLoading';

export const SET_CHANGE_LISTS_LOADING = 'setChangeListsLoading';
export const SET_CHANGE_LISTS = 'setChangeLists';

// frequency
export const SET_MOST_IRREGULAR_FREQUENCY = 'setMostIrregularFrequency';
export const SET_MOST_IRREGULAR_FREQUENCY_WITH_MODULE = `frequencies/${SET_MOST_IRREGULAR_FREQUENCY}`;
export const SET_FREQUENCY_OPTIONS = 'setFrequencyOptions';
export const SET_FREQUENCY_OPTIONS_WITH_MODULE = `frequencies/${SET_FREQUENCY_OPTIONS}`;

// route
export const SET_ROUTE_DATA = 'setRouteData';
export const SET_SORTED_STOPS = 'setSortedStops';
export const SET_SELECTED_STOPS = 'setSelectedStops';
export const SET_ADMINISTRATIVE_AREAS = 'setAdministrativeAreas';
