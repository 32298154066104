/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */
/* eslint-disable no-shadow */
import RouteService from '@/services/route.service';

import * as a from '../actions.type';
import * as m from '../mutations.type';

const state = {
  stops: [],
  sortedStops: [],
  selectedStops: [],
  drivingListId: null,
  startLocation: null,
  disposalPoint: null,
  totalDistance: null,
  totalDuration: null,
  loading: false,
  errors: [],
};

const getters = {
  stops: (state) => state.stops,
  sortedStops: (state) => state.sortedStops,
  selectedStops: (state) => state.selectedStops,
  drivingListId: (state) => state.drivingListId,
  startLocation: (state) => state.startLocation,
  disposalPoint: (state) => state.disposalPoint,
  totalDistance: (state) => state.totalDistance,
  totalDuration: (state) => state.totalDuration,
  routesLoading: (state) => state.loading,
  errors: (state) => state.errors,
};

function removeDuplicates(array, key) {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
}

const actions = {
  async [a.GET_ROUTE]({ commit }, payload) {
    commit(m.SET_ROUTE_LOADING, true);
    try {
      const { data } = await RouteService.getRoute(payload);
      commit(m.SET_ROUTE_DATA, data);
      return data;
    } catch (err) {
      commit(m.SET_ERROR, err);
      throw err;
    } finally {
      commit(m.SET_ROUTE_LOADING, false);
    }
  },
  [a.UPDATE_SORTED_STOPS]({ commit }, sortedStops) {
    const uniqueSortedStops = removeDuplicates(sortedStops, 'planned_instance_id');
    commit(m.SET_SORTED_STOPS, uniqueSortedStops);
  },
  [a.UPDATE_SELECTED_STOPS]({ commit }, selectedStops) {
    const uniqueSelectedStops = removeDuplicates(selectedStops, 'planned_instance_id');
    commit(m.SET_SELECTED_STOPS, uniqueSelectedStops);
  },
};

const mutations = {
  [m.SET_ROUTE_DATA](state, { stops, start_location, disposal_point, driving_list_id, total_distance_in_meter, total_duration_in_seconds }) {
    state.drivingListId = driving_list_id;
    state.stops = stops;
    state.sortedStops = stops.slice().sort((a, b) => a.order - b.order);
    state.startLocation = start_location;
    state.disposalPoint = disposal_point;
    state.totalDistance = total_distance_in_meter;
    state.totalDuration = total_duration_in_seconds;
  },
  [m.SET_SORTED_STOPS](state, sortedStops) {
    state.sortedStops = sortedStops;
  },
  [m.SET_SELECTED_STOPS](state, selectedStops) {
    state.selectedStops = selectedStops;
    console.log(selectedStops);
  },
  [m.SET_ROUTE_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
