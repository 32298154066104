<template>
  <div
    v-click-outside="clickOnOutside"
    class="relative inline-block text-left"
    @click.stop="clickOpen = !clickOpen"
    @mouseenter="setHoverOpen"
    @mouseleave="setHoverClose"
  >
    <div class="cursor-pointer w-full">
      <slot name="trigger" :open="isOpen" />
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isOpen"
        class="origin-top-right absolute mt-2 rounded-md shadow-lg z-60"
        :class="dropdownClass"
      >
        <div class="rounded-md bg-white shadow-xs">
          <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div v-if="$slots.header" class="border-t border-gray-100">
              <slot name="header"></slot>
            </div>

            <div @click.stop="close">
              <slot name="menu"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'BaseDropdown',
  directives: {
    ClickOutside,
  },
  props: {
    trigger: {
      type: String,
      default: 'click',
      description: 'click | hover | Way to trigger the dropdown top open',
    },
    direction: {
      type: String,
      default: 'right',
      description: 'right | left',
    },
    placement: {
      type: String,
      default: 'below',
      description: 'below | above',
    },
  },
  data() {
    return {
      clickOpen: false,
      hoverOpen: false,
      hoverDelay: false,
      forceOpened: false,
    };
  },
  computed: {
    isOpen() {
      if (this.trigger === 'click') {
        return this.clickOpen;
      }

      if (this.trigger === 'hover') {
        return this.hoverOpen;
      }

      return false;
    },
    dropdownClass() {
      const positionClass = this.direction === 'right' ? 'right-0' : 'left-0';
      const placementClass = this.placement === 'below' ? 'mt-2' : 'bottom-full mb-2';
      return `${positionClass} ${placementClass}`;
    },
  },
  methods: {
    close() {
      this.$emit('closed');
      this.clickOpen = false;
      this.hoverOpen = false;
    },
    clickOnOutside() {
      if (this.isOpen && !this.forceOpened) {
        this.close();
      }
    },
    forceOpen() {
      this.forceOpened = true;
      this.clickOpen = !this.clickOpen;
      setTimeout(() => {
        this.forceOpened = false;
      }, 100);
    },
    setHoverOpen() {
      this.hoverOpen = true;
      this.hoverDelay = true;
    },
    setHoverClose() {
      // allow the user to move the cursor outside the menu for a short time
      this.hoverDelay = false;
      setTimeout(() => {
        if (!this.hoverDelay) {
          this.hoverOpen = false;
        }
      }, 100);
    },
  },
};
</script>
