import ApiService from '@/common/api.service';

const RouteService = {
  getRoute(payload) {
    const resource = `routes/?disposal_week=${payload.disposal_week}&vehicle_id=${payload.vehicle_id}&disposal_day=${payload.disposal_day}&include_order=${payload.include_order}`;
    return ApiService.get(resource);
  },
};

export default RouteService;
